import {
  msalApiGetAsync,
  msalApiPostAsync,
  msalApiPatchAsync,
  msalLoggedInUser,
  msalApiPutAsync,
  msalLoggedInUserName,
  msalLoggedInUserDetail,
} from "../provider/msalfetch";
import { IAppContext } from "@msx/platform-services";
import { getPatentContext, isMockData } from "../utils";

const baseUrl = process.env.REACT_APP_WEB_API_ENDPOINT;

export class DelegationAPI {
  /**
   *
   * @summary GetDelegationInfo
   * @throws {RequiredError}
   * @memberof DelegateAPI
   */

  public async getMetadata(parentContext: IAppContext) {
    return msalApiGetAsync(baseUrl + "/delegationinfo", parentContext);
  }

  public async getDelegationInfo(
    toDelete_parentContext: IAppContext,
    toDelete_ismockdata: boolean
  ) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context
    const parentContext = getPatentContext();
    const ismockdata = isMockData(parentContext);

    if (ismockdata) {
      return msalApiGetAsync("/mockData/delegationInfo.json", parentContext);
    } else {
      return msalApiGetAsync(baseUrl + "/getdelegations/getdelegationsinfo", parentContext);
    }
  }

  public async getDelegations(parentContext, ismockdata,yourDelegatesPageNumber,yourDelegatesPageSize,onbehalfDelegatesPageNumber,onbehalfDelegatesPageSize) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context
    if (ismockdata) {
      return msalApiGetAsync("/mockData/getDelegation.json", parentContext);
    } else {
      return msalApiGetAsync(
        baseUrl + `/getdelegations/delegations?yourDelegatesPageNumber=${yourDelegatesPageNumber}&yourDelegatesPageSize=${yourDelegatesPageSize}&onbehalfDelegatesPageNumber=${onbehalfDelegatesPageNumber}&onbehalfDelegatesPageSize=${onbehalfDelegatesPageSize}`,parentContext);    
    }
  }
  public async getressigned(parentContext, ismockdata, reassignedDelegatesPageNumber,reassignedDelegatesPageSize) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context
    if (ismockdata) {
      return msalApiGetAsync("/mockData/getressigned.json", parentContext);
    } else {
      return msalApiGetAsync(baseUrl + `/reassignment?reassignedDelegatesPageNumber=${reassignedDelegatesPageNumber}&reassignedDelegatesPageSize=${reassignedDelegatesPageSize}`, parentContext);
    }
  }
  public async getuserpreferences(parentContext, ismockdata) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context
    if (ismockdata) {
      return msalApiGetAsync("/mockData/userpreferences.json", parentContext);
    } else {
      return msalApiGetAsync(baseUrl + "/userpreferences", parentContext);
    }
  }

  public async getdelegationhistory(parentContext,yourdelegationHistoryPageNumber,yourdelegationHistoryPageSize,onBehalfDelegationHistoryPageNumber,onBehalfDelegationHistoryPageSize) {
    return msalApiGetAsync(baseUrl + `/delegationhistory?yourdelegationHistoryPageNumber=${yourdelegationHistoryPageNumber}&yourdelegationHistoryPageSize=${yourdelegationHistoryPageSize}&onBehalfDelegationHistoryPageNumber=${onBehalfDelegationHistoryPageNumber}&onBehalfDelegationHistoryPageSize=${onBehalfDelegationHistoryPageSize}`, parentContext);
}
  public async getdelegationAuditHistory(parentContext,delegationId) {
      return msalApiGetAsync(baseUrl + `/delegationhistory/delegationid?delegationId=${delegationId}`, parentContext);
  }

  public async checkadminuser(
    parentContext: IAppContext,
    toDelete_ismockdata: boolean,
    ConsumerDocTypeId: string
  ) {
    // cross check  toDelete_ismockdata
    const ismockdata = isMockData(parentContext);

    if (ismockdata) {
      return msalApiGetAsync("/mockData/checkadminuser.json", parentContext);
    } else {
      return msalApiGetAsync(
        baseUrl + `/checkadminuser/${ConsumerDocTypeId}`,
        parentContext
      );
    }
  }

  public getPeopleYouSupport(
    toDelete_parentContext: IAppContext,
    toDelete_ismockdata: boolean,
    peoplesupportedDelegatesPageNumber,
    peoplesupportedDelegatesPageSize,
  ) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context

    const parentContext = getPatentContext();
    const ismockdata = isMockData(parentContext);

    if (ismockdata) {
      return msalApiGetAsync(
        "/mockData/getPeopleSupported.json",
        parentContext
      );
    } else {
      return msalApiGetAsync(baseUrl + `/getpeoplesupported?peoplesupportedDelegatesPageNumber=${peoplesupportedDelegatesPageNumber}&peoplesupportedDelegatesPageSize=${peoplesupportedDelegatesPageSize}`, parentContext);
    }
  }
  public safeLimitData(
    toDelete_parentContext: IAppContext,
    UserAlias: string,
    toDelete_ismockdata: boolean,
    ConsumerDocTypeId: string
  ) {
    // TODO: remove parent context and ismockdata from the function parameter
    // instead use the global context and get the ismockdata from the global context
    const parentContext = getPatentContext();
    const ismockdata = isMockData(parentContext);
    if (ismockdata) {
      return msalApiGetAsync("/mockData/safeLimitMockData.json", parentContext);
    } else {
      const url = baseUrl + `/safelimit/${UserAlias}/${ConsumerDocTypeId}`;
      return msalApiGetAsync(url, parentContext);
    }
  }

  public async safelimitvalidation(
    applicationId: string,
    consumenrDocId: string,
    delegatorAlias: string,
    delegateAlias: string
  ) {
    const parentContext = getPatentContext();
    const ismockdata = isMockData(parentContext);
    let url = "";

    if (ismockdata) {
      return msalApiGetAsync(
        "/mockData/safeLimitValidation.json",
        parentContext
      );
    } else {
      return msalApiGetAsync(
        baseUrl +
          `/safelimitvalidation?applicationId=${applicationId}&consumenrDocId=${consumenrDocId}&delegatorAlias=${delegatorAlias}&delegateAlias=${delegateAlias}`,
        parentContext
      );
    }
  }

  public async getPlatforminfo(parentContext: IAppContext) {
    return msalApiGetAsync(
      baseUrl + "/delegationinfo/platforminfo",
      parentContext
    );
  }

  public createDelegation(
    toDelete_parentContext: IAppContext,
    body: DelegateInfo[]
  ) {
    // TODO: remove parent context from the function parameter
    // instead use the global context and get the ismockdata from the global context

    const parentContext = getPatentContext();
    const url = "/createdelegations";
    return msalApiPostAsync(url, parentContext, JSON.stringify(body)).catch(
      (err) => {
        throw err;
      }
    );
  }
  public createReassigned(
    toDelete_parentContext: IAppContext,
    body: ReassignInfo
  ) {
    // TODO: remove parent context from the function parameter
    // instead use the global context and get the ismockdata from the global context

    const parentContext = getPatentContext();
    const url = "/reassignment";
    return msalApiPostAsync(url, parentContext, JSON.stringify(body)).catch(
      (err) => {
        throw err;
      }
    );
  }
  public setUserpreferences(
    toDelete_parentContext: IAppContext,
    body: UserpreferencesInfo
  ) {
    // TODO: remove parent context from the function parameter
    // instead use the global context and get the ismockdata from the global context

    const parentContext = getPatentContext();
    const url = "/userpreferences";
    console.log("sending request to backend")
    return msalApiPostAsync(url, parentContext, JSON.stringify(body)).catch(
      (err) => {
        throw err;
      }
    );
  }

  public updateDelegation(
    parentContext: IAppContext,
    body: DelegateInfo,
    delegationId: string
  ) {
    const url = `/modifydelegation/update/${delegationId}`;
    return msalApiPutAsync(
      url,
      parentContext,
      delegationId,
      JSON.stringify(body)
    ).catch((err) => {
      throw err;
    });
  }

  public modifyDelegationToExpire(
    parentContext: IAppContext,
    delegationId: string
  ) {
    const url = `/modifydelegation/expire/${delegationId}`;
    return msalApiPutAsync(url, parentContext, delegationId, null);
  }

  public getLoggedInUser(parentContext: IAppContext) {
    return msalLoggedInUser(parentContext);
  }

  public getLoggedInUserName(parentContext: IAppContext) {
    return msalLoggedInUserName(parentContext);
  }

  public getLoggedInUserDetail(parentContext: IAppContext) {
    return msalLoggedInUserDetail(parentContext);
  }
}

export interface DelegateInfo {
  /**
   *
   * @type {Delegate}
   * @memberof delegateInfo
   */
  delegate: Delegate;
  /**
   *
   * @type {date}
   * @memberof delegateInfo
   */
  startDate: string;
  /**
   *
   * @type {date}
   * @memberof delegateInfo
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof delegateInfo
   */
  LoggedInUserNotes: string;
  /**
   *
   * @type {Delegator}
   * @memberof  delegateinfo
   */
  delegator: Delegator;
  /**
   *
   * @type {string}
   * @memberof delegateinfo
   */
  PartnerName: string;
  /**
   *
   * @type {string[]}
   * @memberof delegateinfo
   */
  Permissions: string[];
  /**
   *
   * @type {string}
   * @memberof delegateinfo
   */
  ConsumerDocTypeId: string; //ConsumerDocTypeId
  /**
   *
   * @type {string}
   * @memberof delegateinfo
   */
  Xcv: string;
  /**
   *
   * @type {string}
   * @memberof delegateinfo
   */
  Tcv: string;
  /**
   *
   * @type {string}
   * @memberof delegateinfo
   */
  ApplicationId: string;
}
export interface ReassignInfo {
  // assignedFrom: string;
  assignedFrom: AssignedFrom;
  assignedTo: AssignedTo;
  LoggedInUserNotes: string;
  PartnerName: string;
  ConsumerDocTypeId: string;
  // Xcv: string;
  // Tcv: string;
}
export interface UserpreferencesInfo{
  AppClientId: string,
  LoggedInUserObjctId: string,
  PartnersSupported: PartnerSupported[],
}

export interface PartnerSupported{
  ConsumerDocTypeId: string
}

export interface AssignedFrom {
  Id: string;
  Alias: string;
  Email: string;
  Name: string;
}
export interface AssignedTo {
  Id: string;
  Alias: string;
  Email: string;
  Name: string;
}

export interface Delegate {
  /**
   *
   * @type {string}
   * @memberof delegate
   */
  Email: string;
  /**
   *
   * @type {string}
   * @memberof delegate
   */
  Id: string;
}

export interface Delegate {
  /**
   *
   * @type {string}
   * @memberof delegate
   */
  Email: string;
  /**
   *
   * @type {string}
   * @memberof delegate
   */
  Id: string;
}
export interface Delegator {
  Id: any;
  Email: any;
}
