export enum LogDataKey {
  PartnerName="PartnerName",
  Permissions="Permissions",
  safeLimitData="safeLimitData",
  loginUserAlias="loginUserAlias",
  DelegateEmail="DelegateEmail",
  DelegateObjectId="DelegateObjectId",
  StartDate="StartDate",
  EndDate="EndDate",
  Delegator="Delegator",
  LoggedInUserNotes="LoggedInUserNotes",
  ConsumerDocTypeId="ConsumerDocTypeId",
  Xcv="Xcv",
  Tcv="Tcv",
  DelegateUserAlias="DelegateUserAlias",
  AppName="AppName",
  AppClientId="AppClientId",
  PartnersSupported="PartnersSupported",
  UserAlias="UserAlias",
  CompanyCode="CompanyCode",
  DisbursementTypeCode="DisbursementTypeCode",
  ISOCurrencyCode="ISOCurrencyCode",
  ApproverAlias="ApproverAlias",
  ApprovedDate="ApprovedDate",
  StatusCode="StatusCode",
  ApprovedSAFELimit="ApprovedSAFELimit",
  LoggedInUserEmail="LoggedInUserEmail",
  LoggedInUserObjectId="LoggedInUserObjectId",
  LoggedInUserName="LoggedInUserName",
  DelegateName="DelegateName",
  DelegatorEmail="DelegatorEmail",
  DelegatorObjectId="DelegatorObjectId",
  DelegatorName="DelegatorName",
  IsExpired="IsExpired",
  IsLoggedInUserRemoved="IsLoggedInUserRemoved",
  DelegationStatus="DelegationStatus",
  LoginUserName="LoginUserName",
  DelegationId="DelegationId",
  safeLimitDataDelegator="safeLimitDataDelegator",
    ApplicationId = "ApplicationId",
    DelegatorUserAlias = "DelegatorUserAlias",
    supportedDisbursementTypeCode="supportedDisbursementTypeCode",
  }
  
  export enum TrackingEvent {
 DelegationInfoProcessingAddDelegate = "DelegationInfoProcessingAddDelegate",
 DelegationInfoProcessingReassignment = "DelegationInfoProcessingReassignment",
 DelegationInfoProcessingSettings = "DelegationInfoProcessingSettings",
 DelegationInfoProcessingUpdateDelegate = "DelegationInfoProcessingUpdateDelegate",
 CreateDelegationProcessing = "CreateDelegationProcessing",
 createReassignedProcessing = "createReassignedProcessing",
 UpdateDelegationProcessing = "UpdateDelegationProcessing",
 GetPeopleYouSupportProcessing="GetPeopleYouSupportProcessing",
 GetSafeLimitsProcessingAddDelegate = "GetSafeLimitsProcessingAddDelegate",
 GetSafeLimitsProcessingUpdateDelegate = "GetSafeLimitsProcessingUpdateDelegate",
 GetLoggedInUserProcessingAddDelegate = "GetLoggedInUserProcessingAddDelegate",
 GetLoggedInUserProcessingUpdateDelegate = "GetLoggedInUserProcessingUpdateDelegate",
 GetLoggedInUserNameProcessing="GetLoggedInUserNameProcessing",
 GetyourDelegatesProcessing="GetyourDelegatesProcessing",
 GetreassignmentProcessing="GetreassignmentProcessing",
 DeleteDelegationProcessing="DeleteDelegationProcessing",
 GetEditDataProcessing="GetEditDataProcessing",
 DelegationDashboardGetPeopleYouSupportProcessing="DelegationDashboardGetPeopleYouSupportProcessing",
 GetLoggedInUserProcessingAddDelegator = "GetLoggedInUserProcessingAddDelegator",
 SafelimitvalidationProcessing="SafelimitvalidationProcessing",

DelegationInfoSuccessAddDelegate = "DelegationInfoSuccessAddDelegate",
DelegationInfoSuccessUpdateDelegate = "DelegationInfoSuccessUpdateDelegate",
 CreateDelegationSuccess = "CreateDelegationSuccess",
 UpdateDelegationSuccess = "UpdateDelegationSuccess",
 GetPeopleYouSupportSuccess="GetPeopleYouSupportSuccess",
 GetLoggedInUserNameSuccess="GetLoggedInUserNameSuccess",
 GetyourDelegatesonBehalfDelegatesRecordsSuccess="GetyourDelegatesonBehalfDelegatesRecordsSuccess",
 GetyourDelegatesyourDelegatesRecordsSuccess="GetyourDelegatesyourDelegatesRecordsSuccess",
 DeleteDelegationSuccess="DeleteDelegationSuccess",
 GetEditDataSuccessForonBehalfDelegates="GetEditDataSuccessForonBehalfDelegates",
 GetEditDataSuccessForyourDelegates="GetEditDataSuccessForyourDelegates",
 DelegationDashboardGetPeopleYouSupportSuccess="DelegationDashboardGetPeopleYouSupportSuccess",
 GetSafeLimitsSuccessAddDelegate = "GetSafeLimitsSuccessAddDelegate",
 GetSafeLimitsSuccessUpdateDelegate = "GetSafeLimitsSuccessUpdateDelegate",
 GetLoggedInUserSuccessAddDelegate="GetLoggedInUserSuccessAddDelegate",
 GetLoggedInUserSuccessUpdateDelegate="GetLoggedInUserSuccessUpdateDelegate",
 GetLoggedInUserSuccessAddDelegator = "GetLoggedInUserSuccessAddDelegator",
 GetLoggedInUserSuccessUpdateDelegator = "GetLoggedInUserSuccessUpdateDelegator",
 GetSafeLimitsProcessingUpdateDelegator = "GetSafeLimitsProcessingUpdateDelegator",
 GetSafeLimitsSuccessUpdateDelegator = "GetSafeLimitsSuccessUpdateDelegator",
 GetSafeLimitsFailureUpdateDelegator = "GetSafeLimitsFailureUpdateDelegator",
 GetLoggedInUserProcessingUpdateDelegator = "GetLoggedInUserProcessingUpdateDelegator",

 DelegationInfoFailAddDelegate = "DelegationInfoFailAddDelegate",
 DelegationInfoFailUpdateDelegate = "DelegationInfoFailUpdateDelegate",
 DelegationInfoFailReassignment = "DelegationInfoFailReassignment",
 DelegationInfoFailSavePreferencesFail = "DelegationInfoFailSavePreferencesFail",
 CreateDelegationsFail = "CreateDelegationsFail",
 CreateReassignedFail = "CreateReassignedFail",
 UpdateDelegationsFail = "UpdateDelegationsFail",
 GetyourDelegatesFail="GetyourDelegatesFail",
 GetreassignmentFail="GetreassignmentFail",
 GetHistoryreassignmentFail="GetHistoryreassignmentFail",
 DeleteDelegationFail="DeleteDelegationFail",
 GetEditDataFail="GetEditDataFail",
 DelegationDashboardGetPeopleYouSupportFail="DelegationDashboardGetPeopleYouSupportFail",
 GetSafeLimitsFailureAddDelegate = "GetSafeLimitsFailureAddDelegate",
 GetSafeLimitsFailureUpdateDelegate = "GetSafeLimitsFailureUpdateDelegate",
 GetLoggedInUserFailureAddDelegate="GetLoggedInUserFailureAddDelegate",
 GetLoggedInUserFailureUpdateDelegate="GetLoggedInUserFailureUpdateDelegate",
 GetPeopleYouSupportFail="GetPeopleYouSupportFail",
 SafelimitvalidationFail="SafelimitvalidationFail",
 DataFetched = "DataFetched"
  }
  
  export default { LogDataKey, TrackingEvent };
  