import React, { FC, useEffect, useState, useRef, useContext } from "react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { injectIntl } from "react-intl";
import { messages } from "../delegationDashboard.messages";
import { EmptyResults } from "../shared/EmptyResult";
import {
  Stack,
  IconButton,
  IStyle,
  DetailsList,
  DetailsRow,
  IDetailsRowProps,
  SelectionMode,
  Icon,
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  Dialog,
  DialogType,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";

import { AddDelegate } from "./addDelegate";
import { EditDelegate } from "./editDelegate";
import {
  DetailsListLayoutMode,
  DialogFooter,
  Dropdown,
  IDropdownOption,
  mergeStyleSets,
  on,
} from "office-ui-fabric-react";
import { DelegationAPI } from "../../services/delegateapi";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { isMockData, mockDataMessage } from "../../utils";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from "../../context/TelemetryContext";
import Enums from "../../services/TelemetryService/Enums";
import { IButtonStyles } from "@fluentui/react/lib/Button";
import DelegationAudit from "./delegationAudit";
const addButtonStyles: IButtonStyles = {
  root: {
    marginTop: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align to the left
    cursor: "pointer",
    border: "none",
    background: "none", // Set background to none
    padding: 0, // Remove padding to ensure no background color change on hover
  },
  icon: {
    fontSize: "16px",
    color: "rgb(0, 108, 190)",
  },
  label: {
    color: "rgb(0, 108, 190)",
    cursor: "pointer",
  },
  rootHovered: {
    background: "none !important", // Override Fluent UI default on hover
  },
};
const infoIconClass = mergeStyleSets({
  infoIcon: { cursor: "pointer", verticalAlign: "middle" } as IStyle,
});

const ActiveDelegationsComponent: FC<
  IDelegationDashboardProps & { updateRecordCount: (count: number) => void } & {
    updateHistoryRecordCount: () => void;
  }
> = (props) => {
  const { parentContext, updateRecordCount, updateHistoryRecordCount } = props;
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [onBehalfDelegatesRecords, setonBehalfDelegatesRecords] = useState([]);
  const [yourDelegatesrecords, setyourDelegatesRecords] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const { telemetryClient } = useContext(TelemetryContext);
  const [flag, setFlag] = React.useState(true);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const [sortedonBehalfDelegatesColumn, setSortedonBehalfDelegatesColumn] =
    useState(null); // Currently sorted column
  const [sortedyourDelegatesColumn, setSortedyourDelegatesColumn] =
    useState(null); // Currently sorted column
  const [
    isSortedonBehalfDelegatesDescending,
    setIsSortedonBehalfDelegatesDescending,
  ] = useState(false); // Sort order (ascending/descending)
  const [isSortedyourDelegatesDescending, setIsSortedyourDelegatesDescending] =
    useState(false); // Sort order (ascending/descending)
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
  const [delegationId, setDelegationId] = useState(null);
  const handleRowClick = (item, section) => {
    setSelectedRecord({ item, section });
    setIsDetailPanelOpen(true);
    setDelegationId(item.id);
  };
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false);
  const [deleteErrorDialog, setDeleteErrorDialog] = useState(false);

  const handleAddPanelDismiss = () => {
    // Update the state to close the AddNewDelegate panel
    setIsAddPanelOpen(false);
  };
  const handleEditPanelDismiss = () => {
    // Update the state to close the AddEditDelegate panel
    setIsEditPanelOpen(false);
  };
  const [yourDelegatesPageNumber, setYourDelegatesPageNumber] = useState(1);
  const [yourDelegatesPageSize, setYourDelegatesPageSize] = useState(5);
  const [yourDelegationTotalPages, setYourDelegationTotalPages] = useState(1);
  const [onbehalfDelegatesPageNumber, setOnbehalfDelegatesPageNumber] =
    useState(1);
  const [onbehalfDelegatesPageSize, setOnbehalfDelegatesPageSize] = useState(5); 
  const [onBehalfDelegationTotalPages, setOnBehalfDelegationTotalPages] =
    useState(1);
  const [isYourDelegatesLoading, setYourDelegatesLoading] = useState(false);
  const [isOnBehalfDelegatesLoading, setOnBehalfDelegatesLoading] =
    useState(false);

  const recordsPerPageOptions: IDropdownOption[] = [
    { key: 5, text: "5" },
    { key: 10, text: "10" },
    { key: 20, text: "20" },
    { key: 50, text: "50" },
    { key: 100, text: "100" },
  ];
  const handleYourdelegationHistoryPageNumberChange = (event, option) => {
    setYourDelegatesPageSize(option.key);
    setYourDelegatesPageNumber(1);
  };
  const handleOnBehalfDelegationHistoryPageNumberChange = (event, option) => {
    setOnbehalfDelegatesPageSize(option.key);
    setOnbehalfDelegatesPageNumber(1);
  };

  const renderPagination = (
    totalPages: number,
    currentPage: number,
    pageSize: number,
    handleChange: any,
    setCurrentPage: any,
    isPageLoading: boolean
  ) => {
    const buttonStyles = {
        root: { width: '40px', minWidth: '40px', marginLeft: 'auto', border: 'none', boxShadow: 'none' },
        iconHovered: {color: 'rgb(0, 120, 212)',},
      };
      const arrowStyles = {
        root: { marginBottom:'9px', marginTop:"5px",verticalAlign: "center"  }
      };
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 15 }}
        className={personaStyle.paginationContainer}
        verticalAlign="center"
      >
        <span>Records per page:</span>
        <Dropdown
          selectedKey={pageSize}
          onChange={handleChange}
          options={recordsPerPageOptions}
          className={personaStyle.Dropdown}
        
        />
          <DefaultButton
            iconProps={{ iconName: "ChromeBack" , ...arrowStyles}}
            styles={{
            ...buttonStyles,
            icon: {
                color: currentPage > 1 ? "rgb(0, 120, 212)": 'lightgray'
              },
            }}
            onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
            disabled={currentPage === 1 || isPageLoading}
          />
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <DefaultButton
            iconProps={{ iconName: "ChromeBackMirrored", ...arrowStyles }}
            styles={{
             ...buttonStyles,
              icon: {
                color: currentPage < totalPages ? 'rgb(0, 120, 212)' : 'lightgray', 
              },
            }}
            onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
            disabled={
              currentPage === totalPages || totalPages === 0 || isPageLoading
            }
          />
      </Stack>
    );
  };

  const [ariaLabel, setAriaLabel] = useState("Active Delegations Loading");
  useEffect(() => {
    if (parentContext) {
      setYourDelegatesLoading(true);
      let api = new DelegationAPI();
      let logData: any = {};
      TelemetryService.trackEvent(
        parentContext,
        telemetryClient,
        Enums.TrackingEvent.GetyourDelegatesProcessing,
        props.appName,
        logData
      );
      const ismockdata = isMockData(parentContext);
      api
        .getDelegations(
          parentContext,
          ismockdata,
          yourDelegatesPageNumber,
          yourDelegatesPageSize,
          onbehalfDelegatesPageNumber,
          onbehalfDelegatesPageSize
        )
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const yourDelegatesRecords = res.data.yourDelegates;
            const YourDelegationTotalRecords = res.data.youDelegatesTotalNumberofRecords;
            setYourDelegationTotalPages(
              Math.ceil(YourDelegationTotalRecords / yourDelegatesPageSize)
            );
            setyourDelegatesRecords(yourDelegatesRecords);
            const onBehalfDelegatesTotalRecords =
              res.data.onBehalfOfDelegatesTotalNumberofRecords;
            const count = onBehalfDelegatesTotalRecords + YourDelegationTotalRecords;
            updateRecordCount(count);
          }
        })
        .catch((e: any) => {
          const stackTrace = e.stack || "";
          const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
          const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

          // Extracting Exception type
          const exceptionType = e.message ? e.message.split(":")[0] : "";
          TelemetryService.trackException(
            parentContext,
            telemetryClient,
            Enums.TrackingEvent.GetyourDelegatesFail,
            e.response ? e.response.data : "",
            exceptionType,
            failedMethod,
            props.appName
          );
        })
        .finally(() => {
          setIsPageLoading(false);
          setYourDelegatesLoading(false);
        });
    }
    setAriaLabel(
      label === "Loading" ? "Active Delegations Loading" : "Waiting for data..."
    );
  }, [parentContext, yourDelegatesPageNumber, yourDelegatesPageSize]);
  useEffect(() => {
    if (parentContext) {
      setOnBehalfDelegatesLoading(true);

      let api = new DelegationAPI();
      let logData: any = {};
      TelemetryService.trackEvent(
        parentContext,
        telemetryClient,
        Enums.TrackingEvent.GetyourDelegatesProcessing,
        props.appName,
        logData
      );
      const ismockdata = isMockData(parentContext);
      api
        .getDelegations(
          parentContext,
          ismockdata,
          yourDelegatesPageNumber,
          yourDelegatesPageSize,
          onbehalfDelegatesPageNumber,
          onbehalfDelegatesPageSize
        )
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const YourDelegationTotalRecords =
              res.data.youDelegatesTotalNumberofRecords;
            const onBehalfDelegatesRecords = res.data.onBehalfDelegates;
            const onBehalfDelegatesTotalRecords =
              res.data.onBehalfOfDelegatesTotalNumberofRecords;
            setOnBehalfDelegationTotalPages(
              Math.ceil(
                onBehalfDelegatesTotalRecords / onbehalfDelegatesPageSize
              )
            );
            setonBehalfDelegatesRecords(onBehalfDelegatesRecords);
            const count = onBehalfDelegatesTotalRecords + YourDelegationTotalRecords;
            updateRecordCount(count);
          }
        })
        .catch((e: any) => {
          const stackTrace = e.stack || "";
          const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
          const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

          // Extracting Exception type
          const exceptionType = e.message ? e.message.split(":")[0] : "";
          TelemetryService.trackException(
            parentContext,
            telemetryClient,
            Enums.TrackingEvent.GetyourDelegatesFail,
            e.response ? e.response.data : "",
            exceptionType,
            failedMethod,
            props.appName
          );
        })
        .finally(() => {
          setIsPageLoading(false);
          setOnBehalfDelegatesLoading(false);
        });
    }
    setAriaLabel(
      label === "Loading" ? "Active Delegations Loading" : "Waiting for data..."
    );
  }, [parentContext, onbehalfDelegatesPageNumber, onbehalfDelegatesPageSize]);

  const getDelegationsCall = (parentContext) => {
    if (parentContext) {
      const ismockdata = isMockData(parentContext);
      let api = new DelegationAPI();
      api
        .getDelegations(
          parentContext,
          ismockdata,
          yourDelegatesPageNumber,
          yourDelegatesPageSize,
          onbehalfDelegatesPageNumber,
          onbehalfDelegatesPageSize
        )
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const onBehalfDelegatesRecords = res.data.onBehalfDelegates;
            const yourDelegatesRecords = res.data.yourDelegates;
            const count =
              onBehalfDelegatesRecords.length + yourDelegatesRecords.length;
            setonBehalfDelegatesRecords(onBehalfDelegatesRecords);
            setyourDelegatesRecords(yourDelegatesRecords);
            updateRecordCount(count);
          }
        })
        .catch((e: any) => {});
    }
  };

  const handleClick = () => {
    setIsAddPanelOpen(true);
  };

  const handleEditClick = (itemId, value) => {
    let logData: any = {};

    TelemetryService.trackEvent(
      parentContext,
      telemetryClient,
      Enums.TrackingEvent.GetEditDataProcessing,
      props.appName,
      logData
    );
    let api = new DelegationAPI();
    const ismockdata = isMockData(parentContext);
    api
      .getDelegations(
        parentContext,
        ismockdata,
        yourDelegatesPageNumber,
        yourDelegatesPageSize,
        onbehalfDelegatesPageNumber,
        onbehalfDelegatesPageSize
      )
      .then((res: any) => {
        if (res.data && res.data !== "") {
          if (value === "isonBehalfDelegates") {
            const filteredItems = res.data.onBehalfDelegates.filter(
              (item) => item.id === itemId
            );
            setSelectedEditItem(filteredItems);
            setIsEditPanelOpen(true);
            logData[Enums.LogDataKey.PartnerName] =
              filteredItems[0].partnerName;
            logData[Enums.LogDataKey.ConsumerDocTypeId] =
              filteredItems[0].consumerDocTypeId;
            logData[Enums.LogDataKey.LoggedInUserEmail] =
              filteredItems[0].loggedInUser.email;
            logData[Enums.LogDataKey.LoggedInUserObjectId] =
              filteredItems[0].loggedInUser.id;
            logData[Enums.LogDataKey.LoggedInUserName] =
              filteredItems[0].loggedInUser.name;
            logData[Enums.LogDataKey.DelegateEmail] =
              filteredItems[0].delegate.email;
            logData[Enums.LogDataKey.DelegateObjectId] =
              filteredItems[0].delegate.id;
            logData[Enums.LogDataKey.DelegateName] =
              filteredItems[0].delegate.name;
            logData[Enums.LogDataKey.DelegatorEmail] =
              filteredItems[0].delegator.email;
            logData[Enums.LogDataKey.DelegatorObjectId] =
              filteredItems[0].delegator.id;
            logData[Enums.LogDataKey.DelegatorName] =
              filteredItems[0].delegator.name;
            logData[Enums.LogDataKey.Permissions] =
              filteredItems[0].permissions;
            logData[Enums.LogDataKey.StartDate] = filteredItems[0].startDate;
            logData[Enums.LogDataKey.EndDate] = filteredItems[0].endDate;
            logData[Enums.LogDataKey.LoggedInUserNotes] =
              filteredItems[0].loggedInUserNotes;
            logData[Enums.LogDataKey.IsExpired] = filteredItems[0].isExpired;
            logData[Enums.LogDataKey.IsLoggedInUserRemoved] =
              filteredItems[0].isLoggedInUserRemoved;
            logData[Enums.LogDataKey.DelegationStatus] =
              filteredItems[0].delegationStatus;
            TelemetryService.trackEvent(
              parentContext,
              telemetryClient,
              Enums.TrackingEvent.GetEditDataSuccessForonBehalfDelegates,
              props.appName,
              logData
            );
          } else if (value === "isyourDelegates") {
            const filteredItems = res.data.yourDelegates.filter(
              (item) => item.id === itemId
            );
            setSelectedEditItem(filteredItems);
            setIsEditPanelOpen(true);
            logData[Enums.LogDataKey.PartnerName] =
              filteredItems[0].partnerName;
            logData[Enums.LogDataKey.ConsumerDocTypeId] =
              filteredItems[0].consumerDocTypeId;
            logData[Enums.LogDataKey.LoggedInUserEmail] =
              filteredItems[0].loggedInUser.email;
            logData[Enums.LogDataKey.LoggedInUserObjectId] =
              filteredItems[0].loggedInUser.id;
            logData[Enums.LogDataKey.LoggedInUserName] =
              filteredItems[0].loggedInUser.name;
            logData[Enums.LogDataKey.DelegateEmail] =
              filteredItems[0].delegate.email;
            logData[Enums.LogDataKey.DelegateObjectId] =
              filteredItems[0].delegate.id;
            logData[Enums.LogDataKey.DelegateName] =
              filteredItems[0].delegate.name;
            logData[Enums.LogDataKey.DelegatorEmail] =
              filteredItems[0].delegator.email;
            logData[Enums.LogDataKey.DelegatorObjectId] =
              filteredItems[0].delegator.id;
            logData[Enums.LogDataKey.DelegatorName] =
              filteredItems[0].delegator.name;
            logData[Enums.LogDataKey.Permissions] =
              filteredItems[0].permissions;
            logData[Enums.LogDataKey.StartDate] = filteredItems[0].startDate;
            logData[Enums.LogDataKey.EndDate] = filteredItems[0].endDate;
            logData[Enums.LogDataKey.LoggedInUserNotes] =
              filteredItems[0].loggedInUserNotes;
            logData[Enums.LogDataKey.IsExpired] = filteredItems[0].isExpired;
            logData[Enums.LogDataKey.IsLoggedInUserRemoved] =
              filteredItems[0].isLoggedInUserRemoved;
            logData[Enums.LogDataKey.DelegationStatus] =
              filteredItems[0].delegationStatus;
            TelemetryService.trackEvent(
              parentContext,
              telemetryClient,
              Enums.TrackingEvent.GetEditDataSuccessForyourDelegates,
              props.appName,
              logData
            );
          }
        }
      })
      .catch((e: any) => {
        const stackTrace = e.stack || "";
        const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
        const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

        // Extracting Exception type
        const exceptionType = e.message ? e.message.split(":")[0] : "";
        TelemetryService.trackException(
          parentContext,
          telemetryClient,
          Enums.TrackingEvent.GetEditDataFail,
          e.response ? e.response.data : "",
          exceptionType,
          failedMethod,
          props.appName
        );
      });
  };

  const personaStyle = mergeStyleSets({
    iconButton: { marginTop: 15 } as IStyle,
    tableContainer: {
      marginBottom: 20, 
    },
    paginationContainer: {
      marginTop: "10px !important", 
      textAlign: "center",
      display: "inline-block",
    },
    Dropdown: {
      display: "-webkit-inline-box",
      marginBottom:'8px', marginTop:"10px",verticalAlign: "center",

    },
    title: {
      display: "grid",
    },
    row: {
      selectors: {
        ":hover": {
          color: "rgb(0, 108, 190)",
          cursor: "pointer",
        },
      },
    },
    icon: {
      fontSize: 16,
      marginLeft: 8,
      opacity: 0,
      selectors: {
        ".ms-DetailsRow:hover &": {
          opacity: 1,
        },
      },
    },
  });
  const formatDate = (date) => {
    return new Date(date).toLocaleString([], {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const deleteRecord = (itemId) => {
    // this is delete pop up
    // Initiate the deletion process
    setIsPageLoading(true);
    setFlag(false);
    let api = new DelegationAPI();
    const ismockdata = isMockData(parentContext);
    if (ismockdata) {
      setIsPageLoading(false);
      setDeleteConfirmationDialog(false);
      setRecordToDelete(null);
      alert(mockDataMessage);
      return;
    }
    let logData = {};

    TelemetryService.trackEvent(
      parentContext,
      telemetryClient,
      Enums.TrackingEvent.DeleteDelegationProcessing,
      props.appName,
      logData
    );
    api
      .modifyDelegationToExpire(parentContext, itemId)
      .then((res) => {
        getDelegationsCall(parentContext);
        updateHistoryRecordCount();
        logData[Enums.LogDataKey.DelegationId] = itemId;
        TelemetryService.trackEvent(
          parentContext,
          telemetryClient,
          Enums.TrackingEvent.DeleteDelegationSuccess,
          props.appName,
          logData
        );
      })
      .catch((e) => {
        const stackTrace = e.stack || "";
        const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
        const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

        // Extracting Exception type
        const exceptionType = e.message ? e.message.split(":")[0] : "";
        TelemetryService.trackException(
          parentContext,
          telemetryClient,
          Enums.TrackingEvent.DeleteDelegationFail,
          e.response ? e.response.data : "",
          exceptionType,
          failedMethod,
          props.appName
        );
        setDeleteErrorDialog(true);
      })
      .finally(() => {
        setIsPageLoading(false);
        setDeleteConfirmationDialog(false);
        setRecordToDelete(null);
      });
  };

  const columnsForonBehalfDelegates = [
    {
      key: "delegator",
      name: "Delegator",
      fieldName: "delegator",
      isResizable: true,
      minWidth: 100, 
      minHeight: 10,
      maxWidth: 150,
      isSorted: sortedonBehalfDelegatesColumn === "delegator",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("delegator", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "delegator"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      isFilterable: true,
      ariaLabel: `delegator column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "delegate",
      name: "Delegate",
      fieldName: "delegate",
      isResizable: true,
      minWidth: 100,
      minHeight: 10,
      maxWidth: 150,
      isSorted: sortedonBehalfDelegatesColumn === "delegate",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("delegate", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "delegate"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      isFilterable: true,
      ariaLabel: `delegate column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "application",
      name: "Application",
      fieldName: "application",
      isResizable: true,
      minWidth: 100,
      minHeight: 10,
      maxWidth: 170,
      isSorted: sortedonBehalfDelegatesColumn === "application",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      ariaSort:
        sortedonBehalfDelegatesColumn === "application"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      onColumnClick: () => onColumnClick("application", "isonBehalfDelegates"),
      ariaLabel: `application column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "permissions",
      name: "Permission",
      fieldName: "permissions",
      isResizable: true,
      minWidth: 100,
      minHeight: 10,
      maxWidth: 100,
      isSorted: sortedonBehalfDelegatesColumn === "permissions",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("permissions", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "permissions"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `permissions column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "startDate",
      name: "Start Date",
      fieldName: "startDate",
      isResizable: true,
      minWidth: 100,
      minHeight: 10,
      maxWidth: 150,
      isSorted: sortedonBehalfDelegatesColumn === "startDate",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("startDate", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "startDate"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `startDate column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "endDate",
      name: "End Date",
      fieldName: "endDate",
      isResizable: true,
      minWidth: 100,
      minHeight: 10,
      maxWidth: 150,
      isSorted: sortedonBehalfDelegatesColumn === "endDate",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("endDate", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "endDate"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `endDate column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
      onRender: (item) => {
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
        const isExpiringSoon = new Date(item.endDate) <= oneWeekFromNow;
        return (
          <span>
            {isExpiringSoon && (
              <TooltipHost
                content="Delegation Expiring soon, please update the end date"
                calloutProps={{ role: "alert" }}
              >
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                  tabIndex={0} // Make the button focusable
                  aria-label="Delegation Expiring soon, please update the end date" // ARIA label for screen readers
                >
                  <Icon
                    iconName="Warning"
                    style={{
                      color: "red",
                      marginRight: "8px",
                      fontSize: "15px",
                    }}
                  />
                </button>
              </TooltipHost>
            )}
            <span tabIndex={0}>{item.endDate}</span>{" "}
            {/* Make the date text focusable */}
          </span>
        );
      },
    },
    {
      key: "notes",
      name: "Notes",
      fieldName: "notes",
      isResizable: true,
      minWidth: 70,
      minHeight: 10,
      maxWidth: 100,
      isSorted: sortedonBehalfDelegatesColumn === "notes",
      isSortedDescending: isSortedonBehalfDelegatesDescending,
      onColumnClick: () => onColumnClick("notes", "isonBehalfDelegates"),
      ariaSort:
        sortedonBehalfDelegatesColumn === "notes"
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `notes column is ${
        sortedonBehalfDelegatesColumn
          ? isSortedonBehalfDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "actions",
      name: "Actions",
      isResizable: true,
      minWidth: 50,
      minHeight: 10,
      maxWidth: 100,
      onRender: (item) => (
        <div>
          <IconButton
            iconProps={{ iconName: "Edit" }}
            title="Edit"
            ariaLabel="Edit Delegate"
            onClick={(event) => {
              event.stopPropagation();
              handleEditClick(item.id, "isonBehalfDelegates");
            }}
            aria-describedby={`editButton_${item.id}`}
          />
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Delete"
            ariaLabel="Delete Delegate"
            onClick={(event) => {
              event.stopPropagation();
              setRecordToDelete(item.id);
              setDeleteConfirmationDialog(true);
            }}
            aria-describedby={`deleteButton_${item.id}`}
          />
        </div>
      ),
    },
  ];
  const columnsForyourDelegates = [
    {
      key: "delegate",
      name: "Delegate",
      fieldName: "delegate",
      isResizable: true,
      minWidth: 120,
      minHeight: 60,
      maxWidth: 200,
      isSorted: sortedyourDelegatesColumn === "delegate",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("delegate", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "delegate"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      isFilterable: true,
      ariaLabel: `delegate column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "application",
      name: "Application",
      fieldName: "application",
      isResizable: true,
      minWidth: 120,
      minHeight: 12,
      maxWidth: 150,
      isSorted: sortedyourDelegatesColumn === "application",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("application", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "application"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `application column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "permissions",
      name: "Permission",
      fieldName: "permissions",
      isResizable: true,
      minWidth: 125,
      minHeight: 12,
      maxWidth: 170,
      isSorted: sortedyourDelegatesColumn === "permissions",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("permissions", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "permissions"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `permissions column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "startDate",
      name: "Start Date",
      fieldName: "startDate",
      isResizable: true,
      minWidth: 100,
      minHeight: 12,
      maxWidth: 150,
      isSorted: sortedyourDelegatesColumn === "startDate",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("startDate", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "startDate"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `startDate column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "endDate",
      name: "End Date",
      fieldName: "endDate",
      isResizable: true,
      minWidth: 100,
      minHeight: 12,
      maxWidth: 150,
      isSorted: sortedyourDelegatesColumn === "endDate",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("endDate", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "endDate"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `endDate column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
      onRender: (item) => {
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
        const isExpiringSoon = new Date(item.endDate) <= oneWeekFromNow;
        return (
          <span>
            {isExpiringSoon && (
              <TooltipHost
                content="Delegation Expiring soon, please update the end date"
                calloutProps={{ role: "alert" }}
              >
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                  tabIndex={0} // Make the button focusable
                  aria-label="Delegation Expiring soon, please update the end date" // ARIA label for screen readers
                >
                  <Icon
                    iconName="Warning"
                    style={{
                      color: "red",
                      marginRight: "8px",
                      fontSize: "15px",
                    }}
                  />
                </button>
              </TooltipHost>
            )}
            <span tabIndex={0}>{item.endDate}</span>{" "}
            {/* Make the date text focusable */}
          </span>
        );
      },
    },
    {
      key: "notes",
      name: "Notes",
      fieldName: "notes",
      isResizable: true,
      minWidth: 70,
      minHeight: 12,
      maxWidth: 100,
      isSorted: sortedyourDelegatesColumn === "notes",
      isSortedDescending: isSortedyourDelegatesDescending,
      onColumnClick: () => onColumnClick("notes", "isyourDelegates"),
      ariaSort:
        sortedyourDelegatesColumn === "notes"
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "none", // Update sort status for screen readers
      ariaLabel: `notes column is ${
        sortedyourDelegatesColumn
          ? isSortedyourDelegatesDescending
            ? "descending"
            : "ascending"
          : "not sorted"
      }. Press Enter to sort this column.`,
    },
    {
      key: "actions",
      name: "Actions",
      isResizable: true,
      minWidth: 100,
      minHeight: 12,
      maxWidth: 110,
      onRender: (item) => (
        <div>
          <IconButton
            iconProps={{ iconName: "Edit" }}
            title="Edit"
            ariaLabel="Edit Delegate"
            onClick={(event) => {
              event.stopPropagation();
              handleEditClick(item.id, "isyourDelegates");
            }}
            aria-describedby={`editButton_${item.id}`}
          />
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Delete"
            ariaLabel="Delete Delegate"
            onClick={(event) => {
              event.stopPropagation();
              setRecordToDelete(item.id);
              setDeleteConfirmationDialog(true);
            }}
            aria-describedby={`deleteButton_${item.id}`}
          />
        </div>
      ),
    },
  ];

  const renderRow = (props: IDetailsRowProps) => {
    return (
      <DetailsRow
        {...props}
        className={personaStyle.row}
        styles={{
          root: {
            cursor: "pointer",
            cell: {
              selectors: {
                ":hover": {
                  color: "#0078d4",
                },
              },
            },
          },
        }}
      />
    );
  };
  const onColumnClick = (columnName, value) => {
    if (value === "isonBehalfDelegates") {
      const isCurrentlySortedonBehalfDelegatesDescending =
        sortedonBehalfDelegatesColumn === columnName &&
        isSortedonBehalfDelegatesDescending;
      setSortedonBehalfDelegatesColumn(columnName);
      setIsSortedonBehalfDelegatesDescending(
        !isCurrentlySortedonBehalfDelegatesDescending
      );
      // Sort the records based on the clicked column
      const sortedonBehalfDelegatesRecords = [...onBehalfDelegatesRecords].sort(
        (a, b) => {
          if (columnName === "delegator") {
            const aValue = a.delegator.name.toLowerCase();
            const bValue = b.delegator.name.toLowerCase();
            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "delegate") {
            const aValue = a.delegate.name.toLowerCase();
            const bValue = b.delegate.name.toLowerCase();
            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "application") {
            const aValue = a.partnerName.toLowerCase();
            const bValue = b.partnerName.toLowerCase();
            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "permissions") {
            const aValue = a.permissions.join(", ").toLowerCase();
            const bValue = b.permissions.join(", ").toLowerCase();
            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "startDate" || columnName === "endDate") {
            const aValue = a[columnName];
            const bValue = b[columnName];
            const aDate = new Date(aValue).getTime();
            const bDate = new Date(bValue).getTime();
            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bDate - aDate;
            } else {
              return aDate - bDate;
            }
          } else if (columnName === "notes") {
            const aValue = (a.loggedInUserNotes || "").toLowerCase();
            const bValue = (b.loggedInUserNotes || "").toLowerCase();

            if (isCurrentlySortedonBehalfDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else {
            // Handle sorting for other columns if needed
            return 0; // Add a default return statement for other columns
          }
        }
      );
      setonBehalfDelegatesRecords(sortedonBehalfDelegatesRecords);
    } else if (value === "isyourDelegates") {
      const isCurrentlySortedyourDelegatesDescending =
        sortedyourDelegatesColumn === columnName &&
        isSortedyourDelegatesDescending;
      setSortedyourDelegatesColumn(columnName);
      setIsSortedyourDelegatesDescending(
        !isCurrentlySortedyourDelegatesDescending
      );

      const sortedyourDelegatesRecords = [...yourDelegatesrecords].sort(
        (a, b) => {
          if (columnName === "delegate") {
            const aValue = a.delegate.name.toLowerCase();
            const bValue = b.delegate.name.toLowerCase();
            if (isCurrentlySortedyourDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "application") {
            const aValue = a.partnerName.toLowerCase();
            const bValue = b.partnerName.toLowerCase();
            if (isCurrentlySortedyourDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "permissions") {
            const aValue = a.permissions.join(", ").toLowerCase();
            const bValue = b.permissions.join(", ").toLowerCase();
            if (isCurrentlySortedyourDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else if (columnName === "startDate" || columnName === "endDate") {
            const aValue = a[columnName];
            const bValue = b[columnName];
            const aDate = new Date(aValue).getTime();
            const bDate = new Date(bValue).getTime();
            if (isCurrentlySortedyourDelegatesDescending) {
              return bDate - aDate;
            } else {
              return aDate - bDate;
            }
          } else if (columnName === "notes") {
            const aValue = (a.loggedInUserNotes || "").toLowerCase();
            const bValue = (b.loggedInUserNotes || "").toLowerCase();

            if (isCurrentlySortedyourDelegatesDescending) {
              return bValue.localeCompare(aValue);
            } else {
              return aValue.localeCompare(bValue);
            }
          } else {
            // Handle sorting for other columns if needed
            return 0; // Add a default return statement for other columns
          }
        }
      );
      setyourDelegatesRecords(sortedyourDelegatesRecords);
    }
  };

  const label = flag ? "Loading" : "Wait...";
  let submitloader = isPageLoading ? (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "rgba(229, 229, 229, 0.5)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        id="Activedelegations-spinner"
        label={label}
        labelPosition="right"
        ariaLabel={ariaLabel}
        size={SpinnerSize.large}
      />
    </div>
  ) : (
    ""
  );

  const ActiveDelegationHeader = (
    <div>
      <div style={{ display: "inline-block" }}>
        <h2
          style={{ fontSize: "18px", marginTop: "10px", marginBottom: "0px" }}
        >
          Active Delegations
        </h2>
      </div>
      <div style={{ display: "inline", fontSize: "18px" }}>
        <TooltipHost
          content="These are people you have delegated to and who can act on your behalf"
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.topCenter}
          id="tooltip-Active-delegations"
        >
          <span
            role="tooltip"
            tabIndex={0} // Makes the icon focusable with the keyboard
            aria-label="Active Delegations"
            aria-labelledby="tooltip-Active-delegations"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                const targetElement = e.target as HTMLElement;

                if (targetElement.click) {
                  targetElement.click(); // Now TypeScript will not complain
                }
              }
            }}
            // Add margin to create space between text and icon
            style={{ marginLeft: "8px" }} 
          >
            <Icon iconName="Info" className={infoIconClass.infoIcon} />
          </span>
        </TooltipHost>
      </div>
    </div>
  );

  const renderContent = () => {
    const renderActions = (item, value) => (
      <>
        <IconButton
          iconProps={{ iconName: "Edit" }}
          title="Edit"
          ariaLabel="Edit"
          onClick={() => handleEditClick(item.id, value)}
        />
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete"
          ariaLabel="Delete"
          onClick={() => {
            const itemId = item.id;
            setIsPageLoading(true);
            setFlag(false);
            let api = new DelegationAPI();
            api
              .modifyDelegationToExpire(parentContext, itemId)
              .then((res: any) => {
                getDelegationsCall(parentContext);
                setIsPageLoading(false);
              })
              .catch((e: any) => {});
          }}
        />
      </>
    );

    return (
      <div className={personaStyle.iconButton}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <DefaultButton
            text={props.intl.formatMessage(messages.rightPanelheaderText)}
            iconProps={{ iconName: "AddFriend" }}
            styles={addButtonStyles}
            onClick={handleClick}
            ariaLabel="Add new delegate"
            role="button"
          />
        </Stack>
        {isAddPanelOpen && (
          <AddDelegate
            parentContext={parentContext}
            updateRecordCount={updateRecordCount}
            IsAddPanelOpen={true}
            onDismiss={handleAddPanelDismiss}
            appName={props.appName}
            getDelegation={getDelegationsCall}
          />
        )}
        {isEditPanelOpen && (
          <EditDelegate
            parentContext={parentContext}
            updateRecordCount={updateRecordCount}
            items={selectedEditItem}
            IsEditPanelOpen={true}
            onEditDismiss={handleEditPanelDismiss}
            appName={props.appName}
            getDelegation={getDelegationsCall}
          />
        )}
        <Stack tokens={{ childrenGap: 5 }}>
          {submitloader}

          {yourDelegatesrecords.length > 0 ||
          onBehalfDelegatesRecords.length > 0 ? (
            <div>
              {yourDelegatesrecords.length > 0 && (
                <Stack
                  tokens={{ childrenGap: 5 }}
                  styles={{ root: { paddingTop: 5 } }}
                >
                  <div>
                    {ActiveDelegationHeader}
                    <div style={{ position: "relative" }}>
                      {isYourDelegatesLoading && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: "80%",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            zIndex: 20,
                          }}
                        >
                          <Spinner
                            size={SpinnerSize.small}
                            label="Loading"
                            ariaLabel="Loading data"
                          />
                        </div>
                      )}
                      <DetailsList
                        items={yourDelegatesrecords.map(
                          ({
                            id,
                            delegate,
                            partnerName,
                            permissions,
                            startDate,
                            endDate,
                            loggedInUserNotes,
                          }) => {
                            const [firstName, lastName] = delegate.name
                              ? delegate.name.split(" ")
                              : "";
                            const username = delegate.email
                              ? delegate.email.split("@")[0]
                              : "";
                            return {
                              id,
                              delegate: `${firstName} ${lastName}`,
                              application: partnerName ? partnerName : "app1",
                              permissions: permissions
                                ? permissions.join(", ")
                                : "",
                              startDate: formatDate(startDate),
                              endDate: formatDate(endDate),
                              notes: loggedInUserNotes,
                              username: username,
                            };
                          }
                        )}
                        columns={columnsForyourDelegates}
                        compact={true}
                        styles={{
                          contentWrapper: {
                            maxHeight: 200,
                            height: "auto",
                            overflowX: "hidden",
                          },
                        }}
                        selectionMode={SelectionMode.none}
                        onItemInvoked={(item) =>
                          handleRowClick(item, "isyourDelegates")
                        }
                        ariaLabelForGrid="Active Delegations Table"
                        onRenderItemColumn={(item, index, column) => {
                          const value = "isyourDelegates";
                          if (column.key === "actions") {
                            return (
                              <div id={`row_${item.id}`}>
                                {renderActions(item, value)}
                              </div>
                            );
                          }
                          if (column.key === "delegate") {
                            return (
                              <TooltipHost content={item.username}>
                                <div id={`row_${item.id}`}>
                                  {item[column.fieldName]}
                                </div>
                              </TooltipHost>
                            );
                          }
                          return item[column.fieldName];
                        }}
                        onRenderRow={(props) => {
                          const customRowProps = {
                            ...props,
                            onClick: () =>
                              handleRowClick(props.item, "isyourDelegates"),
                          };
                          return renderRow(customRowProps);
                        }}
                      />
                    </div>
                  </div>
                  {renderPagination(
                    yourDelegationTotalPages,
                    yourDelegatesPageNumber,
                    yourDelegatesPageSize,
                    handleYourdelegationHistoryPageNumberChange,
                    setYourDelegatesPageNumber,
                    isYourDelegatesLoading
                  )}
                </Stack>
              )}

              <Stack
                tokens={{ childrenGap: 5 }}
                styles={{ root: { paddingTop: 5 } }}
              >
                {onBehalfDelegatesRecords.length > 0 && (
                  <div>
                    <div>
                      <div style={{ display: "inline-block" }}>
                        <h2
                          style={{
                            fontSize: "18px",
                            marginTop: "10px",
                            marginBottom: "0px",
                          }}
                        >
                          On Behalf of Delegations
                        </h2>
                      </div>
                      <div
                        style={{ display: "inline-block", fontSize: "18px" }}
                      >
                        <TooltipHost
                          content="These are people you delegated to using your administrator role"
                          delay={TooltipDelay.zero}
                          directionalHint={DirectionalHint.topCenter}
                          id="tooltip-OnBehalfof-delegations"
                        >
                          <span
                            role="tooltip"
                            tabIndex={0}
                            aria-label="On Behalf of Delegations"
                            aria-labelledby="tooltip-OnBehalfof-delegations"
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                e.preventDefault();
                                const targetElement = e.target as HTMLElement;

                                if (targetElement.click) {
                                  targetElement.click(); // Now TypeScript will not complain
                                }
                              }
                            }}
                            // Add margin to create space between text and icon
                            style={{ marginLeft: "8px" }} // Adjust the value as needed
                          >
                            <Icon
                              iconName="Info"
                              className={infoIconClass.infoIcon}
                            />
                          </span>
                        </TooltipHost>
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      {isOnBehalfDelegatesLoading && (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",

                            zIndex: 20,
                          }}
                        >
                          <Spinner
                            size={SpinnerSize.small}
                            label="Loading"
                            ariaLabel="Loading data"
                          />
                        </div>
                      )}

                      <DetailsList
                        items={onBehalfDelegatesRecords.map(
                          ({
                            id,
                            delegator,
                            delegate, 
                            partnerName,
                            permissions,
                            startDate,
                            endDate,
                            loggedInUserNotes,
                          }) => {
                            const [delegatorfirstName, delegatorlastName] =
                              delegator.name ? delegator.name.split(" ") : "";
                            const delegatorusername = delegator.email
                              ? delegator.email.split("@")[0]
                              : "";
                            const [delegatefirstName, delegatelastName] =
                              delegate.name ? delegate.name.split(" ") : "";
                            const delegateusername = delegate.email
                              ? delegate.email.split("@")[0]
                              : "";
                            return {
                              id,
                              delegator: `${delegatorfirstName} ${delegatorlastName}`,
                              delegate: `${delegatefirstName} ${delegatelastName}`,
                              application: partnerName ? partnerName : "app1",
                              permissions: permissions
                                ? permissions.join(", ")
                                : "",
                              startDate: formatDate(startDate), // Format the start date
                              endDate: formatDate(endDate),
                              notes: loggedInUserNotes,
                              delegatorusername: delegatorusername,
                              delegateusername: delegateusername,
                            };
                          }
                        )}
                        columns={columnsForonBehalfDelegates}
                        compact={true}
                        styles={{
                          contentWrapper: {
                            maxHeight: 200,
                            height: "auto",
                            overflowX: "hidden",
                          },
                        }}
                        selectionMode={SelectionMode.none}
                        onItemInvoked={(item) =>
                          handleRowClick(item, "onBehalfDelegates")
                        }
                        onRenderItemColumn={(item, index, column) => {
                          const value = "isonBehalfDelegates";
                          if (column.key === "actions") {
                            return (
                              <div id={`row_${item.id}`}>
                                {renderActions(item, value)}
                              </div>
                            );
                          }
                          if (column.key === "delegate") {
                            return (
                              <TooltipHost content={item.delegateusername}>
                                <div id={`row_${item.id}`}>
                                  {item[column.fieldName]}
                                </div>
                              </TooltipHost>
                            );
                          }
                          if (column.key === "delegator") {
                            return (
                              <TooltipHost content={item.delegatorusername}>
                                <div id={`row_${item.id}`}>
                                  {item[column.fieldName]}
                                </div>
                              </TooltipHost>
                            );
                          }
                          return item[column.fieldName];
                        }}
                        onRenderRow={(props) => {
                          const customRowProps = {
                            ...props,
                            onClick: () =>
                              handleRowClick(props.item, "isonBehalfDelegates"),
                          };
                          return renderRow(customRowProps);
                        }}
                        ariaLabelForGrid="On Behalf of DelegationsTable"
                      />
                    </div>
                  </div>
                )}
              </Stack>
            </div>
          ) : (
            <div>
              <div style={{ paddingTop: "5px" }}>{ActiveDelegationHeader}</div>
              <div style={{ textAlign: "center" }}>
                 <EmptyResults message="You have no active delegations" />
              </div>
            </div>
          )}

          {onBehalfDelegatesRecords.length > 0 &&
            renderPagination(
              onBehalfDelegationTotalPages,
              onbehalfDelegatesPageNumber,
              onbehalfDelegatesPageSize,
              handleOnBehalfDelegationHistoryPageNumberChange,
              setOnbehalfDelegatesPageNumber,
              isOnBehalfDelegatesLoading
            )}
          <Dialog
            hidden={!deleteConfirmationDialog}
            onDismiss={() => {
              setDeleteConfirmationDialog(false);
            }}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Confirm Deletion",
              closeButtonAriaLabel: "Close",
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 450 } },
            }}
          >
            <div>
              <p>Are you sure you would like to delete the delegation?</p>
            </div>
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  setDeleteConfirmationDialog(false);
                  setIsPageLoading(true);
                  deleteRecord(recordToDelete);
                }}
                text="Ok"
              />

              <DefaultButton
                onClick={() => setDeleteConfirmationDialog(false)}
                text="Cancel"
              />
            </DialogFooter>
          </Dialog>
          <Dialog
            hidden={!deleteErrorDialog}
            onDismiss={() => {
              setDeleteConfirmationDialog(false);
              setDeleteErrorDialog(false);
            }}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Error for Delete Deletion",
              closeButtonAriaLabel: "Close",
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 450 } },
            }}
          >
            <div>
              <p>
                An error occurred while performing this action, please try
                again.
              </p>
            </div>
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  setIsPageLoading(false);
                  setDeleteErrorDialog(false);
                  setDeleteConfirmationDialog(false);
                }}
                text="Ok"
              />
            </DialogFooter>
          </Dialog>
        </Stack>

        <DelegationAudit
          isOpen={isDetailPanelOpen}
          onClose={() => setIsDetailPanelOpen(false)}
          selectedRecord={selectedRecord}
          formatDate={formatDate}
          delegationId={delegationId}
          props={props}
        />
      </div>
    );
  };

  const renderMain = (): JSX.Element => {
    return <Stack>{renderContent()}</Stack>;
  };
  return renderMain();
};

export const ActiveDelegations = injectIntl(ActiveDelegationsComponent);
