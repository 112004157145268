import React, { FC, useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  PrimaryButton,
  Stack,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { DelegationAPI } from "../../services/delegateapi";
import { isMockData } from "../../utils";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import TelemetryContext from "../../context/TelemetryContext";
import Enums from "../../services/TelemetryService/Enums";

const SettingsComponent: FC<IDelegationDashboardProps> = (props) => {
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300, marginTop: 10 } };

  const parentContext = props.parentContext;
  const { telemetryClient } = useContext(TelemetryContext); // Use telemetry context

  interface IExtendedDropdownOption extends IDropdownOption {
    id: string;
    supportedDisbursementTypeCode?: string[] | null;
  }

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const ismockdata = isMockData(parentContext);
  const [listPartnersSupported, setListPartnersSupported] = useState([]);
  const [appClientId, setAppClientId] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true); // Set initial loading state to true
  const [message, setMessage] = useState<{
    text: string;
    type: MessageBarType;
  } | null>(null);

  const SubmitUserPreferences = async () => {
    setIsPageLoading(true);
    setMessage(null);
    let api = new DelegationAPI();
    try {
      const loggedInUser = await api.getLoggedInUserDetail(parentContext);
      const requestBody = {
        AppClientId: appClientId,
        LoggedInUserObjctId: loggedInUser.optionalText,
        PartnersSupported: listPartnersSupported
          .filter((item) => selectedKeys.includes(item.partnerName))
          .map((item) => ({ ConsumerDocTypeId: item.consumerDocTypeId })),
      };
      await api.setUserpreferences(parentContext, requestBody);
      setMessage({
        text: "Preferences saved successfully!",
        type: MessageBarType.success,
      });
      TelemetryService.trackEvent(
        parentContext,
        telemetryClient,
        Enums.TrackingEvent.DelegationInfoProcessingSettings,
        props.appName,
        {
          AppClientId: appClientId,
          LoggedInUserObjctId: loggedInUser.optionalText,
        }
      );
    } catch (error) {
      console.error("Error submitting preferences:", error);
      setMessage({
        text: "Failed to save preferences. Please try again.",
        type: MessageBarType.error,
      });
      TelemetryService.trackException(
        parentContext,
        telemetryClient,
        Enums.TrackingEvent.DelegationInfoFailSavePreferencesFail,
        error.message,
        error.name,
        "SubmitUserPreferences",
        props.appName
      );
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const api = new DelegationAPI();
      try {
        const metadata = (await api.getMetadata(parentContext)).data;
        const partnersSupported = metadata.partnersSupported;
        setListPartnersSupported(partnersSupported);
        const userPreferences = await api.getuserpreferences(
          parentContext,
          ismockdata
        );
        const userPreferencePartnersSupported =
          userPreferences.data.partnersSupported.map(
            (partner) => partner.consumerDocTypeId
          );

        const selectedApps = partnersSupported
          .filter((partner) =>
            userPreferencePartnersSupported.includes(partner.consumerDocTypeId)
          )
          .map((partner) => partner.partnerName);

        setSelectedKeys(selectedApps);
        TelemetryService.trackEvent(
          parentContext,
          telemetryClient,
          Enums.TrackingEvent.DataFetched,
          props.appName,
          {
            AppClientId: metadata.appClientId,
          }
        );
      } catch (error) {
        console.error("Error fetching data:", error);

        TelemetryService.trackException(
          parentContext,
          telemetryClient,
          Enums.TrackingEvent.DelegationInfoFailSavePreferencesFail,
          error.message,
          error.name,
          "fetchData",
          props.appName
        );
      } finally {
        setIsPageLoading(false); 
      }
    };

    fetchData();
  }, [parentContext, telemetryClient, props.appName]);

  const partnerOptions: IExtendedDropdownOption[] = listPartnersSupported.map(
    (partner) => ({
      key: partner.partnerName,
      id: partner.consumerDocTypeId,
      text: partner.partnerName,
      supportedDisbursementTypeCode: partner.supportedDisbursementTypeCode,
    })
  );
  const label = isPageLoading ? "Loading..." : "Wait...";
  const submitLoader = isPageLoading ? (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "rgba(229, 229, 229, 0.5)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        id="Activedelegations-spinner"
        label={label}
        labelPosition="right"
        ariaLabel={label}
        size={SpinnerSize.large}
      />
    </div>
  ) : null;

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      {submitLoader}
      {message && (
        <MessageBar
          messageBarType={message.type}
          isMultiline={false}
          onDismiss={() => setMessage(null)}
          dismissButtonAriaLabel="Close"
        >
          {message.text}
        </MessageBar>
      )}

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Dropdown
          label="Select your favorite application(s)"
          placeholder="Select your favorite application(s)"
          options={partnerOptions}
          selectedKeys={selectedKeys}
          onChange={(event, option) => {
            if (option) {
              const updatedKeys = option.selected
                ? [...selectedKeys, option.key as string]
                : selectedKeys.filter((key) => key !== option.key);

              setSelectedKeys(updatedKeys);
            }
          }}
          multiSelect
          styles={dropdownStyles} 
        />

        <PrimaryButton
          text="Save Preferences"
          onClick={SubmitUserPreferences}
          styles={{ root: { width: 150, marginTop: 38 } }} 
          disabled={selectedKeys.length === 0}
        />
      </Stack>
    </Stack>
  );
};

export const Settings = injectIntl(SettingsComponent);